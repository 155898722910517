<template>
  <div>
    <b-card
      no-body
      class="mb-2 ml-0 mr-0"
      v-if="!evaluatee_view && !evaluator_view"
    >
      <b-card-header
        header-tag="header"
        class="p-0 d-flex ml-0 mr-0"
        role="tab"
      >
        <b-button
          block
          v-b-toggle.accordion-1
          class="btn-collapse ml-0 mr-0 p-1 noprint"
          variant="info"
          @click="isAccordionOpen = !isAccordionOpen"
        >
          COEVALUACIÓN
          <b-icon v-if="isAccordionOpen" icon="caret-down-fill"></b-icon>
          <b-icon v-else icon="caret-up-fill"></b-icon>
        </b-button>
      </b-card-header>
      <b-collapse
        id="accordion-1"
        visible
        accordion="my-accordion"
        role="tabpanel"
      >
        <b-card-body>
          <div
            class="d-flex asd"
            v-if="
              (!existEvaluateeTest ||
                filterEvaluateeRubrics.filter((x) => x.coevaluators.length > 0)
                  .length > 0) &&
              allows_crud
            "
          >
            <span class="mr-1 align-self-center"
              ><strong>Asignar estudiantes de forma Aleatoria:</strong></span
            >
            <b-button
              size="sm"
              variant="primary"
              class="p-1"
              @click="randomizeCoevaluators()"
              >Asignar</b-button
            >
            <b-button
              v-if="!existEvaluateeTest"
              size="sm"
              variant="danger"
              class="p-1 ml-2"
              @click="unassignCoevaluators()"
              >Limpiar</b-button
            >
          </div>
          <div class="container-body-collapse mt-3">
            <div class="table-evaluatees">
              <b-table-simple bordered hover>
                <b-thead>
                  <b-tr>
                    <b-th class="th-table" style="width: 10%"
                      >IDENTIFICADOR</b-th
                    >
                    <b-th class="th-table">NOMBRES</b-th>
                    <b-th class="th-table">APELLIDOS</b-th>
                    <b-th class="th-table" style="width: 40%">COEVALUAR</b-th>
                  </b-tr>
                </b-thead>
                <b-tbody>
                  <b-tr v-for="evaluatee in filterUser" :key="evaluatee.id">
                    <b-td class="td-table">{{
                      evaluatee.user_data ? evaluatee.user_data.identifier : ""
                    }}</b-td>
                    <b-td class="td-table">{{ evaluatee.first_name }}</b-td>
                    <b-td class="td-table">{{ evaluatee.last_name }}</b-td>
                    <b-td class="td-table">
                      <CoEvaluatorSelectedInput
                        v-if="rubric"
                        :key="
                          reset_coevaluator_input +
                          '-' +
                          evaluatee.id +
                          rubric.coevaluation_type
                        "
                        :rubric="rubric"
                        :test="test"
                        :evaluatee="evaluatee.id"
                        :allows_crud="!existEvaluateeTest ? allows_crud : false"
                      ></CoEvaluatorSelectedInput>
                    </b-td>
                  </b-tr>
                </b-tbody>
              </b-table-simple>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>
    <b-table-simple
      v-if="
        (coevaluateeUsers.length > 0 && evaluator_view) ||
        (coevaluatorUsers.length > 0 &&
          evaluatee_view &&
          is_running_test &&
          !finished_test) ||
        (evaluatee_view &&
          finished_test &&
          coevaluateeUsers.length > 0 &&
          rubric.evaluatee_can_see_coevaluator)
      "
      bordered
      hover
      class="table-coevaluator"
    >
      <b-thead>
        <b-tr>
          <b-th class="th-coevaluator" style="width: 50%"
            ><span v-if="evaluator_view || (finished_test && evaluatee_view)"
              >COEVALUADOR{{ coevaluateeUsers.length > 1 ? "ES" : "" }}</span
            ><span v-else-if="!finished_test && is_running_test"
              >ESTAS COEVALUANDO:</span
            >
          </b-th>
          <!-- <b-th class="th-coevaluator" style="width: 50%" v-if="!evaluatee_view"
            >EVALUADO</b-th
          > -->
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr>
          <b-td>
            <div v-if="(evaluatee_view && finished_test) || evaluator_view">
              {{
                coevaluateeUsers
                  .map((x) => {
                    if (x.user_data)
                      return (
                        x.user_data.identifier +
                        " " +
                        x.first_name +
                        " " +
                        x.last_name
                      );
                    else return x.first_name + " " + x.last_name;
                  })
                  .join(", ")
              }}
            </div>
            <div v-else-if="!finished_test && is_running_test">
              <template v-for="(evaluator, index) in coevaluatorUsers">
                <span
                  v-if="completeCoevaluatorRubric(evaluator.id)"
                  class="text-success"
                  :key="evaluator.id + '-' + test.id"
                  v-b-tooltip.v-success.top.noninteractive="'Evaluado'"
                >
                  {{ evaluator.user_data ? evaluator.user_data.identifier : ""
                  }}{{ evaluator.first_name }} {{ evaluator.last_name
                  }}{{ index + 1 != coevaluatorUsers.length ? ", " : " " }}
                </span>
                <span
                  v-else
                  :key="evaluator.id + '-' + test.id"
                  class="text-danger"
                  v-b-tooltip.v-danger.top.noninteractive="'Falta Evaluar'"
                >
                  {{ evaluator.user_data ? evaluator.user_data.identifier : ""
                  }}{{ evaluator.first_name }} {{ evaluator.last_name
                  }}{{ index + 1 != coevaluatorUsers.length ? ", " : " " }}
                </span>
              </template>
            </div>
          </b-td>
          <!-- <b-td v-if="!evaluatee_view">
            <div v-if="evaluaterUser">
              {{ evaluaterUser.first_name + " " + evaluaterUser.last_name }}
            </div>
          </b-td> -->
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <div></div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import * as names from "@/store/names";
import { toast } from "@/utils/utils";

export default {
  name: "CoEvaluatorRubric",
  components: {
    CoEvaluatorSelectedInput: () =>
      import("@/components/new-evaluations/Rubrics/CoEvaluatorSelectedInput"),
  },
  props: {
    rubric: {
      type: Object,
      required: true,
    },
    test: {
      type: Object,
      required: true,
    },
    allows_crud: {
      type: Boolean,
      default: false,
    },
    evaluatee_view: {
      type: Boolean,
      default: false,
    },
    evaluator_view: {
      type: Boolean,
      default: false,
    },
    user_id: {
      type: Number,
      required: false,
    },
    is_running_test: {
      type: Boolean,
      default: false,
    },
    finished_test: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isAccordionOpen: false,
      reset_coevaluator_input: 0,
    };
  },
  computed: {
    ...mapGetters({
      user: "getUser",
      users: names.USERS,
      evaluatee_rubrics: names.EVALUATEE_RUBRICS,
      evaluatee_tests: names.EVALUATEE_TESTS,
      rubric_criterias: names.NEW_RUBRIC_CRITERIAS,
      EvaRubCriPerLevScore: names.EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVELS,
      EvaRubCriPerLevEvaluators:
        names.EVALUATEE_RUBRIC_CRITERIA_PERFORMANCE_LEVEL_EVALUATORS,
    }),
    evaluaterUser() {
      return this.users.find((x) => x.id == this.user_id);
    },
    filterUser() {
      return this.users.filter((x) => this.test.evaluatees.includes(x.id));
    },
    existEvaluateeTest() {
      if (
        this.evaluatee_tests.filter(
          (x) =>
            x.test == this.test.id &&
            (x.start_time != null ||
              x.is_corrected ||
              x.is_finished ||
              x.is_published)
        ).length > 0
      )
        return true;
      else return false;
    },
    filterEvaluateeRubrics() {
      return this.evaluatee_rubrics.filter((x) => x.rubric == this.rubric.id);
    },
    evaluateeRubric() {
      if (
        (this.evaluatee_view && this.user_id) ||
        (this.evaluator_view && this.user_id)
      )
        return this.evaluatee_rubrics.find(
          (x) => x.evaluatee == this.user_id && x.rubric == this.rubric.id
        );
      else return null;
    },
    coevaluatorUsers() {
      if (this.evaluateeRubric)
        return this.filterUser.filter((x) =>
          this.evaluateeRubric.coevaluators.includes(x.id)
        );
      else return [];
    },
    coevaluateeUsers() {
      if (this.evaluateeRubric)
        return this.filterUser.filter((x) =>
          this.evaluateeRubric.coevaluating.includes(x.id)
        );
      else return [];
    },
    rubricCriteriasIds() {
      return this.rubric_criterias
        .filter((x) => x.rubric == this.rubric.id)
        .map((x) => x.id);
    },
  },
  methods: {
    completeCoevaluatorRubric(coevaluator_id) {
      const coevaluators_rubric_ids = this.EvaRubCriPerLevScore.filter(
        (x) =>
          x.evaluatee == coevaluator_id &&
          this.rubricCriteriasIds.includes(x.rubric_criteria)
      ).map((x) => x.id);
      if (coevaluators_rubric_ids.length > 0 && this.user) {
        if (
          this.EvaRubCriPerLevEvaluators.filter(
            (x) =>
              x.evaluator == this.user.id &&
              coevaluators_rubric_ids.includes(
                x.evaluatee_rubric_criteria_performance_level
              ) &&
              x.rubric_performance_level != null
          ).length == this.rubricCriteriasIds.length
        )
          return true;
        else return false;
      }
      return false;
    },
    randomizeCoevaluators() {
      if (this.rubric)
        this.$restful
          .Get(
            `/evaluations2/randomize_rubric_coevaluators/?rubric=${this.rubric.id}`
          )
          .then(() => {
            this.$store.dispatch(names.FETCH_EVALUATEE_RUBRICS, {
              test_id: this.test.id,
            });
            toast("Evaluados Asignados Satisfactoriamente");
          });
    },
    unassignCoevaluators() {
      if (this.rubric)
        this.$restful
          .Get(
            `/evaluations2/unassign_rubric_coevaluators/?rubric=${this.rubric.id}`
          )
          .then(() => {
            this.$store
              .dispatch(names.FETCH_EVALUATEE_RUBRICS, {
                test_id: this.test.id,
              })
              .then(() => {
                this.reset_coevaluator_input++;
                toast("Evaluados Desasignados Satisfactoriamente");
              });
          });
    },
  },
  mounted() {},
  watch: {
    test(test) {
      if (test.evaluatees.length > 0)
        this.$store.dispatch(names.FETCH_ALT_USERS, {
          user_ids: test.evaluatees,
        });
    },
  },
  created() {
    this.$store.dispatch(names.FETCH_ALT_USERS, {
      user_ids: this.test.evaluatees,
    });
    if (!this.evaluatee_view && !this.evaluator_view)
      this.$store.dispatch(names.FETCH_EVALUATEE_RUBRICS, {
        test_id: this.test.id,
      });
  },
};
</script>

<style scoped>
.table-coevaluator {
  font-size: var(--secondary-font-size);
}
.th-coevaluator {
  background-color: var(--kl-menu-color) !important;
  color: white !important;
}
.btn-collapse {
  font-weight: bold;
  cursor: pointer;
}
.container-body-collapse {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
}
.table-evaluatees {
  width: 100% !important;
}
.th-table {
  background-color: var(--kl-menu-color) !important;
  color: white !important;
  padding: 0.15rem;
}
.select-evaluatee {
  min-width: 400px;
}
.asd {
  justify-content: right;
}
@media print {
  #accordion-1 {
    display: block !important; /* Forza la visibilidad del contenido del acordeón */
  }
}
</style>